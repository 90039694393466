import React, { useState } from 'react';
import Navbar from '../Navbar';
import Sidebar from '../Sidebar';
import Footer from '../Footer';
import {
  HeroContainer,
  HeroHeader,
  LeftDiv,
  RightDiv,
  NavBtn,
  NavBtnLink,
  Img,
  ImgWrap,
  NavExternalBtn,
  NavBtnExternalLink
} from '../ServicesPricing/ServicePricingElements';
import ScrollToTop from '../ScrollToTop';
import useWindowDimensions from '../useWindowDimensions';

function LocationsPage() {

  const { height, width } = useWindowDimensions();
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  var pageMargins = "50px"
  if (width < 1000) {
    pageMargins = "10px"
  }
  if (width < 600) {
    pageMargins = "5px"
  }

  const howItWorksTableContent = [
    {
      imgPath: require('../../images/how_it_works_signup_icon.png'),
      title: 'Sign Up',
      description: 'The process begins when you create a FREE Tumble account & provide us with your laundry care preferences.'
    },
    {
      imgPath: require('../../images/how_it_works_schedule_your_pickup_icon.png'),
      title: 'Schedule Your Pickup',
      description: 'Choose a time and date for one of our Tumble Associates to come and pick up your precious cargo. Select between scheduling a one-time, bi-weekly or weekly pickup.'
    },
    {
      imgPath: require('../../images/how_it_works_wash_and_fold_icon.png'),
      title: 'Wash',
      description: 'Our Team will wash, dry, and fold your items based on the preferences you have provided us at the time of your sign up.'
    },
    {
      imgPath: require('../../images/how_it_works_confirmation_icon.png'),
      title: 'Confirmation',
      description: 'Before we set out to deliver your laundry we\'ll send you a confirmation text or email asking when you\'d like us to drop off your items.'
    },
    {
      imgPath: require('../../images/how_it_works_delivery_icon.png'),
      title: 'Delivery',
      description: 'Voilà! Standard delivery time is 2-4 days.'
    }
  ]

  const howItWorksTable = () => {
    if (width < 1100) {
      const tableRowList = []
      var index = 0
      howItWorksTableContent.forEach((rowObject) => {
        tableRowList.push(
          <React.Fragment key={index}>
            <tr>
              <td>
                <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
                  {
                    (width > 650) ? (
                      <>
                        <img src={rowObject.imgPath} width={"25px"} style={{ float: "left" }} alt={rowObject.title} />
                        <th style={{ paddingBottom: "10px", paddingTop: "10px", paddingLeft: "10px", color: "#4E8AA2" }}>{rowObject.title}</th>
                      </>
                    ) : (
                      <>
                        <th style={{ paddingBottom: "10px", paddingTop: "10px", paddingLeft: "0px", color: "#4E8AA2" }}>{rowObject.title}</th>
                      </>
                    )
                  }

                </div>
              </td>
              <td style={{ paddingRight: "15px", paddingTop: "10px", paddingBottom: "10px", verticalAlign: "top" }}>{rowObject.description}</td>
            </tr>
            {
              ((howItWorksTableContent.length - index) > 1) ? (
                <tr>
                  <td colSpan="2">
                    <hr />
                  </td>
                </tr>
              ) : (
                <></>
              )
            }

          </React.Fragment>
        )
        index += 1
      })
      var sideMargins = "50px"
      if (width < 600) {
        sideMargins = "5px"
      }
      return (
        <div style={{ textAlign: "center", marginTop: "75px", marginBottom: "25px", flex: "100%", width: "100%", textAlign: "left", backgroundColor: "#d3d3d3", borderRadius: "10px" }}>
          <h2 style={{ width: "100%", paddingTop: "25px", paddingBottom: "25px", paddingLeft: "25px", paddingRight: "25px", fontSize: "23px", color: "#4E8AA2" }}>How it works</h2>
          <table style={{ width: "100%", textAlign: "left", fontSize: "23px", borderStyle: "none", paddingLeft: sideMargins, paddingRight: sideMargins, paddingBottom: "25px", tableLayout: "fixed" }}>
            <tbody>
              {tableRowList}
            </tbody>
          </table>
        </div>
      )
    }
    else {
      const headerImageRowList = []
      const headerTitleRowList = []
      const descriptionList = []
      var index = 0
      howItWorksTableContent.forEach((rowObject) => {
        headerImageRowList.push(
          <td key={index}>
            <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
              <img src={rowObject.imgPath} width={"25px"} style={{ float: "left" }} alt={rowObject.title} />
              <div style={{ float: "right", width: "100%", backgroundColor: "black", height: "1px", top: "50%", marginLeft: "10px", marginRight: "10px" }}></div>
            </div>
          </td>
        )
        headerTitleRowList.push(
          <th key={index} style={{ paddingBottom: "10px", paddingTop: "10px", color: "#4E8AA2" }}>{rowObject.title}</th>
        )
        descriptionList.push(
          <td style={{ paddingRight: "15px", paddingTop: "10px", verticalAlign: "top" }}>{rowObject.description}</td>
        )
        index += 1
      })
      return (
        <div style={{ textAlign: "center", marginTop: "75px", marginBottom: "25px", flex: "100%", width: "100%", textAlign: "left", backgroundColor: "#d3d3d3", borderRadius: "10px" }}>
          <h2 style={{ width: "100%", paddingTop: "25px", paddingBottom: "25px", paddingLeft: "25px", paddingRight: "25px", fontSize: "23px", color: "#4E8AA2" }}>How it works</h2>
          <table style={{ width: "100%", textAlign: "left", fontSize: "23px", borderStyle: "none", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px", tableLayout: "fixed" }}>
            <tbody>
              <tr>
                {headerImageRowList}
              </tr>
              <tr>
                {headerTitleRowList}
              </tr>
              <tr>
                {descriptionList}
              </tr>
            </tbody>
          </table>
        </div>
      )
    }
  }

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <div>
        <ScrollToTop />
        <HeroHeader></HeroHeader>
        <div style={{ marginLeft: pageMargins, marginRight: pageMargins }}>
          <HeroContainer>
            <LeftDiv>
              <h1 style={{ paddingTop: "80px", fontSize: "55px" }}>
                Tumble Locations
              </h1>
              <h3 style={{ paddingTop: "60px", color: "#4E8AA2", fontSize: "27px", paddingBottom: "15px" }}>
                Areas Served
              </h3>
              <p style={{ width: "70%", fontSize: "23px", marginBottom: "50px" }}>We pick up & deliver within a 20 mile radius of Stockton, Tracy, and Livermore. Our pickup and delivery times are Monday-Friday 8AM - 1PM.</p>
              <NavExternalBtn>
                <NavBtnExternalLink href='https://app.tumbledelivery.com/'>Schedule Pickup</NavBtnExternalLink>
              </NavExternalBtn>
            </LeftDiv>
            <RightDiv>
              <ImgWrap>
                <Img src={require('../../images/tumble_map.png')} alt={'hi'} />
              </ImgWrap>
            </RightDiv>
            {howItWorksTable()}
          </HeroContainer>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default LocationsPage;
