import React from 'react';
import './App.css';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './pages';
import SigninPage from './pages/signin';
import Services from './pages/services';
import Locations from './pages/locations';
import Testimonials from './pages/testimonials'
import Contact from './pages/contact'
import FAQ from './pages/FAQ';

function App() {
  return (
    <Router>
      <Switch>
        <Route path='/' component={Home} exact />
        <Route path='/signin' component={SigninPage} exact />
        <Route path='/services' component={Services} />
        <Route path='/locations' component={Locations} />
        <Route path='/testimonials' component={Testimonials} />
        <Route path='/contact' component={Contact} />
        <Route path='/faq' component={FAQ} />
      </Switch>
    </Router>
  );
}

export default App;
