export const homeObjOne = {
  id: 'locations',
  lightBg: false,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Locations & Times',
  headline: 'Delivery Locations & Times',
  description:
    "We pick up & deliver within a 20 mile radius of Stockton, Tracy, and Livermore. Our pickup and delivery times are Monday-Friday 8AM - 1PM.",
  buttonLabel: 'Sign Up',
  imgStart: false,
  img: require('../../images/tumble_map.png'),
  alt: 'tumble_map',
  dark: false,
  primary: true,
  darkText: true,
  externalButton: true,
  buttonDestination: 'https://app.tumbledelivery.com/'
};

export const homeObjTwo = {
  id: 'app',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Mobile App',
  headline: 'Download the App!',
  description:
    'The Tumble app is available on Android & iOS.  Check Laundry off your to-do list with just the click of a button!  Watch this video to learn more.',
  buttonLabel: 'Learn More',
  imgStart: true,
  img: require('../../images/mobile-app.jpg'),
  alt: 'Car',
  dark: false,
  primary: true,
  darkText: true,
  externalButton: true,
  buttonDestination: 'https://app.tumbledelivery.com/'
};

export const homeObjThree = {
  id: 'signup',
  lightBg: false,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Sign Up Now!',
  headline: 'Get a 20% Discount!',
  description:
    'Create your free account now to get a 20% discount on your first purchase!  It\'s easy and takes less than five minutes.',
  buttonLabel: 'Sign Up',
  imgStart: false,
  img: require('../../images/tumble12.jpg'),
  alt: 'Car',
  dark: false,
  primary: true,
  darkText: true,
  externalButton: true,
  buttonDestination: 'https://app.tumbledelivery.com/'
};