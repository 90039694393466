export const DRY_CLEAN_PRICES = {
    'Pants': {
        'price': '$7.50',
        'img': 'https://cdn-icons-png.flaticon.com/512/88/88823.png'
    },
    '2 pc Suits': {
        'price': '$14.50',
        'img': 'https://fashionsista.co/downloadpng/png/20200803/suit-user-wooing-two-piece-svg-png-icon-free-download.jpg'
    },
    '3 pc Suits': {
        'price': '$18.50',
        'img': 'https://cdn2.iconfinder.com/data/icons/huge-basic-vector-icons-part-3-3/512/suit_user_wooing_two-piece-512.png'
    },
    'Shirt Laundered': {
        'price': '$6',
        'img': 'https://cdn.iconscout.com/icon/premium/png-256-thumb/dress-shirt-3077149-2557859.png'
    },
    'Shirt Dry Cleaned': {
        'price': '$8',
        'img': 'https://cdn.iconscout.com/icon/premium/png-256-thumb/dress-shirt-3077149-2557859.png'
    },
    'Jackets': {
        'price': '$10',
        'img': 'https://cdn-icons-png.flaticon.com/512/124/124666.png'
    },
    'Coats': {
        'price': '$14',
        'img': 'https://cdn-icons-png.flaticon.com/512/207/207687.png'
    },
    'Dresses': {
        'price': '$11.50',
        'img': 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/62/Dress_icon.svg/1200px-Dress_icon.svg.png'
    },
    'Skirts': {
        'price': '$8.50',
        'img': 'https://cdn-icons-png.flaticon.com/512/141/141584.png'
    },
    'Blouses': {
        'price': '$8.50',
        'img': 'https://cdn-icons-png.flaticon.com/512/81/81424.png'
    },
    'Uniforms': {
        'price': '$11.50',
        'img': 'https://static.thenounproject.com/png/857259-200.png'
    }
}

export const DEV = false;