import React, { useState, useEffect } from 'react';
import { FaBars } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import { animateScroll as scroll } from 'react-scroll';
import TumbleIcon from '../../images/Tumble_Logo.png'
import {
  MobileIcon,
  Nav,
  NavbarContainer,
  NavItem,
  NavLinks,
  NavLogo,
  NavIcon,
  NavMenu,
  NavBtn,
  NavBtnLink,
  NavBtnExternalLink
} from './NavbarElements';
import { useHistory } from 'react-router-dom';

const Navbar = ({ toggle }) => {
  const history = useHistory()
  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeNav);
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  const goHome = () => {
    history.push("/")
  }

  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>
        <Nav scrollNav={scrollNav}>
          <NavbarContainer>
            {/* <NavLogo onClick={toggleHome} to='/'>
              tumble
            </NavLogo> */}
            <NavIcon src={TumbleIcon} onClick={goHome} />
            <MobileIcon onClick={toggle}>
              <FaBars color={scrollNav ? "#000" : "#fff"} />
            </MobileIcon>
            <NavMenu>
              <NavItem>
                <NavLinks
                  to='/'
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact='true'
                  offset={-80}
                  scrollnav={scrollNav}
                >
                  Home
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  to='/services'
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact='true'
                  offset={-80}
                  scrollnav={scrollNav}
                >
                  Services & Pricing
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  to='/locations'
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact='true'
                  offset={-80}
                  scrollnav={scrollNav}
                >
                  Locations
                </NavLinks>
              </NavItem>
            </NavMenu>
            <NavBtn>
              <NavBtnExternalLink href='https://app.tumbledelivery.com/'>Login</NavBtnExternalLink>
            </NavBtn>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
};

export default Navbar;
