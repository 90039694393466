import React, { useState } from 'react';
import { Button } from '../ButtonElements';
import Navbar from '../Navbar';
import Sidebar from '../Sidebar';
import Footer from '../Footer';
import Video from '../../videos/tumble_video.MP4';
import {
  HeroContainer,
  HeroHeader,
  LeftDiv,
  RightDiv,
  NavBtn,
  NavExternalBtn,
  NavBtnLink,
  NavBtnExternalLink,
  Img,
  ImgWrap
} from './ServicePricingElements';
import ScrollToTop from '../ScrollToTop';
import useWindowDimensions from '../useWindowDimensions';
import { Helmet } from 'react-helmet';

function WashAndFold() {

  const { height, width } = useWindowDimensions();

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  const howItWorksTableContent = [
    {
      imgPath: require('../../images/how_it_works_signup_icon.png'),
      title: 'Sign Up',
      description: 'The process begins when you create a FREE Tumble account & provide us with your laundry care preferences.'
    },
    {
      imgPath: require('../../images/how_it_works_schedule_your_pickup_icon.png'),
      title: 'Schedule Your Pickup',
      description: 'Choose a time and date for one of our Tumble Associates to come and pick up your precious cargo. Select between scheduling a one-time, bi-weekly or weekly pickup.'
    },
    {
      imgPath: require('../../images/how_it_works_wash_and_fold_icon.png'),
      title: 'Wash & Fold',
      description: 'Our Team will wash, dry, and fold your items based on the preferences you have provided us at the time of your sign up.'
    },
    {
      imgPath: require('../../images/how_it_works_confirmation_icon.png'),
      title: 'Confirmation',
      description: 'Before we set out to deliver your laundry we\'ll send you a confirmation text or email asking when you\'d like us to drop off your items.'
    },
    {
      imgPath: require('../../images/how_it_works_delivery_icon.png'),
      title: 'Delivery',
      description: 'Voilà! Standard delivery time is 2-4 days.'
    }
  ]

  const howItWorksTable = () => {
    if (width < 1100) {
      const tableRowList = []
      var index = 0
      howItWorksTableContent.forEach((rowObject) => {
        tableRowList.push(
          <React.Fragment key={index}>
            <tr>
              <td>
                <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
                  {
                    (width > 650) ? (
                      <>
                        <img src={rowObject.imgPath} width={"25px"} style={{ float: "left" }} alt={rowObject.title} />
                        <th style={{ paddingBottom: "10px", paddingTop: "10px", paddingLeft: "10px", color: "#4E8AA2" }}>{rowObject.title}</th>
                      </>
                    ) : (
                      <>
                        <th style={{ paddingBottom: "10px", paddingTop: "10px", paddingLeft: "0px", color: "#4E8AA2" }}>{rowObject.title}</th>
                      </>
                    )
                  }

                </div>
              </td>
              <td style={{ paddingRight: "15px", paddingTop: "10px", paddingBottom: "10px", verticalAlign: "top" }}>{rowObject.description}</td>
            </tr>
            {
              ((howItWorksTableContent.length - index) > 1) ? (
                <tr>
                  <td colSpan="2">
                    <hr />
                  </td>
                </tr>
              ) : (
                <></>
              )
            }

          </React.Fragment>
        )
        index += 1
      })
      var sideMargins = "50px"
      if (width < 600) {
        sideMargins = "5px"
      }
      return (
        <div style={{ textAlign: "center", marginTop: "75px", marginBottom: "25px", flex: "100%", width: "100%", textAlign: "left", backgroundColor: "#d3d3d3", borderRadius: "10px" }}>
          <h2 style={{ width: "100%", paddingTop: "25px", paddingBottom: "25px", paddingLeft: "25px", paddingRight: "25px", fontSize: "23px", color: "#4E8AA2" }}>How it works</h2>
          <table style={{ width: "100%", textAlign: "left", fontSize: "23px", borderStyle: "none", paddingLeft: sideMargins, paddingRight: sideMargins, paddingBottom: "25px", tableLayout: "fixed" }}>
            <tbody>
              {tableRowList}
            </tbody>
          </table>
        </div>
      )
    }
    else {
      const headerImageRowList = []
      const headerTitleRowList = []
      const descriptionList = []
      var index = 0
      howItWorksTableContent.forEach((rowObject) => {
        headerImageRowList.push(
          <td key={index}>
            <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
              <img src={rowObject.imgPath} width={"25px"} style={{ float: "left" }} alt={rowObject.title} />
              <div style={{ float: "right", width: "100%", backgroundColor: "black", height: "1px", top: "50%", marginLeft: "10px", marginRight: "10px" }}></div>
            </div>
          </td>
        )
        headerTitleRowList.push(
          <th key={index} style={{ paddingBottom: "10px", paddingTop: "10px", color: "#4E8AA2" }}>{rowObject.title}</th>
        )
        descriptionList.push(
          <td style={{ paddingRight: "15px", paddingTop: "10px", verticalAlign: "top" }}>{rowObject.description}</td>
        )
        index += 1
      })
      return (
        <div style={{ textAlign: "center", marginTop: "75px", marginBottom: "25px", flex: "100%", width: "100%", textAlign: "left", backgroundColor: "#d3d3d3", borderRadius: "10px" }}>
          <h2 style={{ width: "100%", paddingTop: "25px", paddingBottom: "25px", paddingLeft: "25px", paddingRight: "25px", fontSize: "23px", color: "#4E8AA2" }}>How it works</h2>
          <table style={{ width: "100%", textAlign: "left", fontSize: "23px", borderStyle: "none", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px", tableLayout: "fixed" }}>
            <tbody>
              <tr>
                {headerImageRowList}
              </tr>
              <tr>
                {headerTitleRowList}
              </tr>
              <tr>
                {descriptionList}
              </tr>
            </tbody>
          </table>
        </div>
      )
    }
  }

  var pageMargins = "50px"
  if (width < 1000) {
    pageMargins = "10px"
  }
  if (width < 600) {
    pageMargins = "5px"
  }

  return (
    <>
      <Helmet>
        <title>Wash and Fold</title>
        <meta
          name="description"
          content="Tumble offers Wash and Fold delivery services, priced per pound of laundry."
        />
        <meta
          name="keywords"
          content="commercial laundering, hang dry, Laundry near me, Laundry, Laundromat, Wash and fold, Wash and fold service, Pickup and delivery, Laundry pickup service, Laundry pickup, Dry cleaning, Dry cleaning delivery, Laundry delivery, Laundry delivery service, Delivery, Stockton, 95219, 95207, 95376, Tracy, Livermore, Modesto, Manteca, Lodi, Discovery bay"
        />
      </Helmet>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <div>
        <ScrollToTop />
        <HeroHeader></HeroHeader>
        {/* <div style={{ width: "100%", textAlign: "center" }}>
        <h1 style={{ paddingTop: "20px", paddingBottom: "20px", color: "#4E8AA2", margin: "auto" }}>Services and Pricing</h1>
      </div> */}
        <div style={{ width: "100%", textAlign: "center", backgroundColor: "#92c1d4" }}>
          <table style={{ width: "100%", textAlign: "center", paddingTop: "20px", paddingBottom: "20px" }}>
            <thead>
              <tr>
                <th><a href="/services/wash-and-fold" style={{ color: "black" }}>Wash & Fold</a></th>
                <th><a href="/services/dry-cleaning" style={{ color: "black", textDecoration: "none" }}>Dry Cleaning</a></th>
                <th><a href="/services/other-services" style={{ color: "black", textDecoration: "none" }}>Other Services</a></th>
              </tr>
            </thead>
          </table>
        </div>
        <div style={{ marginLeft: pageMargins, marginRight: pageMargins }}>
          <HeroContainer>
            <LeftDiv>
              <h1 style={{ paddingTop: "80px", fontSize: "55px" }}>
                Wash & Fold
              </h1>
              <h3 style={{ paddingTop: "60px", color: "#4E8AA2", fontSize: "27px", paddingBottom: "15px" }}>
                Pickup & Delivery
              </h3>
              <p style={{ width: "70%", fontSize: "23px" }}>We pick up Monday-Friday between 8 AM - 1 PM.  You can complete pickups/deliveries without coming to your door or being home with our contactless delivery option!  Deliveries are complete 2-4 days after the pickup day.</p>
              <h3 style={{ paddingTop: "50px", color: "#4E8AA2", fontSize: "27px", paddingBottom: "15px" }}>
                In a Rush?
              </h3>
              <p style={{ width: "70%", fontSize: "23px", marginBottom: "50px" }}>If you need your delivery next day, please let our team know.  There is a next day delivery fee of $10 per pickup.</p>
              <NavExternalBtn>
                <NavBtnExternalLink href='https://app.tumbledelivery.com/'>Schedule Pickup</NavBtnExternalLink>
              </NavExternalBtn>
            </LeftDiv>
            <RightDiv>
              <ImgWrap>
                <Img src={require('../../images/tumble11.jpg')} alt={'hi'} />
              </ImgWrap>
              <div style={{ borderRadius: "10px", boxShadow: "0 1px 3px rgba(0, 0, 0, 0.2)", maxWidth: "100%", textAlign: "center", marginTop: "25px" }}>
                <p style={{ width: "100%", paddingTop: "25px", paddingBottom: "25px", paddingLeft: "25px", paddingRight: "25px", fontSize: "23px" }}>Please see our standard pickup and delivery schedule.</p>
                <table style={{ width: "100%", textAlign: "left", fontSize: "23px", borderStyle: "none", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px" }}>
                  <tbody>
                    <tr>
                      <th style={{ paddingBottom: "10px", paddingTop: "10px" }}>Pickup</th>
                      <th style={{ textAlign: "right", paddingBottom: "10px", paddingTop: "10px" }}>Delivery</th>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <hr />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ paddingBottom: "10px", paddingTop: "10px" }}>Monday</td>
                      <td style={{ textAlign: "right", paddingBottom: "10px", paddingTop: "10px" }}>Wednesday</td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <hr />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ paddingBottom: "10px", paddingTop: "10px" }}>Tuesday</td>
                      <td style={{ textAlign: "right", paddingBottom: "10px", paddingTop: "10px" }}>Thursday</td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <hr />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ paddingBottom: "10px", paddingTop: "10px" }}>Wednesday</td>
                      <td style={{ textAlign: "right", paddingBottom: "10px", paddingTop: "10px" }}>Friday</td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <hr />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ paddingBottom: "10px", paddingTop: "10px" }}>Thursday</td>
                      <td style={{ textAlign: "right", paddingBottom: "10px", paddingTop: "10px" }}>Monday</td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <hr />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ paddingBottom: "10px", paddingTop: "10px" }}>Friday</td>
                      <td style={{ textAlign: "right", paddingBottom: "10px", paddingTop: "10px" }}>Monday</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </RightDiv>
            {howItWorksTable()}
            <LeftDiv>
              <h3 style={{ paddingTop: "50px", color: "#4E8AA2", fontSize: "27px", paddingBottom: "15px" }}>
                Pricing
              </h3>
              <p style={{ width: "70%", paddingBottom: "50px", fontSize: "23px" }}>For wash and fold, our prices are determined by the number of pounds you give us for each pickup. We charge $1.89 per pound with a minimum order of $25 per pick up. There are no pesky hidden fees like many of our competitors. </p>
              <NavExternalBtn>
                <NavBtnExternalLink href='https://app.tumbledelivery.com/'>Schedule Pickup</NavBtnExternalLink>
              </NavExternalBtn>
            </LeftDiv>
            <RightDiv>
              <div style={{ width: "100%", paddingTop: "50px", minWidth: "250px" }}>
                <div style={{ backgroundColor: "#244959" }}>
                  <h3 style={{ color: "white", padding: "10px", paddingTop: "15px", fontSize: "30px" }}>Price per Pound</h3>
                  <p style={{ color: "white", padding: "10px", paddingBottom: "15px" }}><i>As little as $1.89/lb</i></p>
                </div>
                <div style={{ borderStyle: "solid" }}>
                  <p style={{ padding: "10px" }}>The average person does 15 lbs of laundry per week. This is an approximation of how much laundry that is:</p>
                  <table style={{ width: "100%", tableLayout: "fixed", textAlign: "left", alignItems: "center", padding: "10px", maxWidth: "100%" }}>
                    <tbody>
                      <tr style={{}}>
                        <td>
                          <div style={{ display: "flex", width: "100%", alignItems: "center", paddingBottom: "10px", paddingLeft: "10px" }}>
                            <img src="https://cdn-icons-png.flaticon.com/512/88/88823.png" width={"25px"} style={{ float: "left" }} />
                            <p style={{ float: "right", paddingLeft: "10px" }}>Pants</p>
                          </div>
                        </td>
                        <td style={{ paddingBottom: "10px", textAlign: "right", paddingRight: "10px" }}>5</td>
                      </tr>
                      <tr>
                        <td colSpan="2">
                          <hr />
                        </td>
                      </tr>
                      <tr style={{}}>
                        <td>
                          <div style={{ display: "flex", width: "100%", alignItems: "center", paddingBottom: "10px", paddingTop: "10px", paddingLeft: "10px" }}>
                            <img src="https://cdn3.iconfinder.com/data/icons/laundry-dry-cleaning/64/462_laundry-clothes-hanger-tshirt-512.png" width={"25px"} style={{ float: "left" }} />
                            <p style={{ float: "right", paddingLeft: "10px" }}>Shirts</p>
                          </div>
                        </td>
                        <td style={{ paddingBottom: "10px", textAlign: "right", paddingRight: "10px" }}>2</td>
                      </tr>
                      <tr>
                        <td colSpan="2">
                          <hr />
                        </td>
                      </tr>
                      <tr style={{}}>
                        <td>
                          <div style={{ display: "flex", width: "100%", alignItems: "center", paddingBottom: "10px", paddingTop: "10px", paddingLeft: "10px" }}>
                            <img src="https://cdn4.iconfinder.com/data/icons/everyday-objects-line-art-1/128/towels-512.png" width={"25px"} style={{ float: "left" }} />
                            <p style={{ float: "right", paddingLeft: "10px" }}>Towels</p>
                          </div>
                        </td>
                        <td style={{ paddingBottom: "10px", textAlign: "right", paddingRight: "10px" }}>2</td>
                      </tr>
                      <tr>
                        <td colSpan="2">
                          <hr />
                        </td>
                      </tr>
                      <tr style={{}}>
                        <td>
                          <div style={{ display: "flex", width: "100%", alignItems: "center", paddingBottom: "10px", paddingTop: "10px", paddingLeft: "10px" }}>
                            <img src="https://cdn3.iconfinder.com/data/icons/casual-wear-1/100/sweat-512.png" width={"25px"} style={{ float: "left" }} />
                            <p style={{ float: "right", paddingLeft: "10px" }}>Sweatshirts</p>
                          </div>
                        </td>
                        <td style={{ paddingBottom: "10px", textAlign: "right", paddingRight: "10px" }}>4</td>
                      </tr>
                      <tr>
                        <td colSpan="2">
                          <hr />
                        </td>
                      </tr>
                      <tr style={{}}>
                        <td>
                          <div style={{ display: "flex", width: "100%", alignItems: "center", paddingBottom: "10px", paddingTop: "10px", paddingLeft: "10px" }}>
                            <img src="https://cdn4.iconfinder.com/data/icons/socks-and-underwear/48/v-11-512.png" width={"25px"} style={{ float: "left" }} />
                            <p style={{ float: "right", paddingLeft: "10px" }}>Undergarments</p>
                          </div>
                        </td>
                        <td style={{ paddingBottom: "10px", textAlign: "right", paddingRight: "10px" }}>8</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </RightDiv>
          </HeroContainer>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default WashAndFold;
