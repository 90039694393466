import React from 'react';
import WasherIcon from '../../images/washer.png'
import DryCleaningIcon from '../../images/dry-clean.png'
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP
} from './ServicesElements';

const Services = () => {
  return (
    <ServicesContainer id='services'>
      <ServicesH1>Our Services</ServicesH1>
      <ServicesWrapper>
        <ServicesCard to='/services/wash-and-fold'>
          <ServicesIcon src={WasherIcon} />
          <ServicesH2>Wash & Fold</ServicesH2>
          <ServicesP>
          Wash & fold adds freetime back to your schedule. Give Tumble as much laundry as you would like, whenever you would like, and we’ll wash it however you like. It’s time to take a load off. 
          </ServicesP>
        </ServicesCard>
        <ServicesCard to='/services/dry-cleaning'>
          <ServicesIcon src={DryCleaningIcon} />
          <ServicesH2>Dry Cleaning</ServicesH2>
          <ServicesP>
          Dry cleaning is great for items you want professionally cleaned and returned pressed and on a hanger. Relax, you will never have to go to the dry cleaners again.
          </ServicesP>
        </ServicesCard>
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default Services;
