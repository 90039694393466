import React, { useState } from 'react';
import Navbar from '../Navbar';
import Sidebar from '../Sidebar';
import Footer from '../Footer';
import {
  HeroContainer,
  HeroHeader,
  LeftDiv,
  RightDiv,
  NavBtn,
  NavBtnLink,
  Img,
  ImgWrap
} from '../ServicesPricing/ServicePricingElements';
import ScrollToTop from '../ScrollToTop';

function ContactPage() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };
  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <div>
        <ScrollToTop />
        <HeroHeader></HeroHeader>
        <HeroContainer>
          <LeftDiv>
            <h1 style={{ paddingTop: "80px", fontSize: "55px" }}>
              Contact Us
            </h1>
            <h3 style={{ paddingTop: "60px", color: "#4E8AA2", fontSize: "27px", paddingBottom: "15px" }}>
              Phone 
            </h3>
            <p style={{ width: "70%", fontSize: "23px" }}>(209) 407-1576</p>
            <h3 style={{ paddingTop: "60px", color: "#4E8AA2", fontSize: "27px", paddingBottom: "15px" }}>
              Email 
            </h3>
            <p style={{ width: "70%", fontSize: "23px" }}>admin@tumbledelivery.com</p>
          </LeftDiv>
          <RightDiv>
            <ImgWrap>
              <Img src={require('../../images/tumble10.jpg')} alt={'hi'} />
            </ImgWrap>
          </RightDiv>
        </HeroContainer>
      </div>
      <Footer />
    </>
  );
}

export default ContactPage;
