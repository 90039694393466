import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import HeroSection from '../components/HeroSection';
import InfoSection from '../components/InfoSection';
import {
  homeObjOne,
  homeObjTwo,
  homeObjThree
} from '../components/InfoSection/Data';
import Services from '../components/Services';
import { Helmet } from "react-helmet";

function Home() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <Helmet>
        <title>Tumble Delivery</title>
        <meta
          name="description"
          content="Tumble is a laundry delivery service serving Stockton, Tracy, and Livermore in California."
        />
        <meta
          name="keywords"
          content="Laundry near me, Laundry, Laundromat, Wash and fold, Wash and fold service, Pickup and delivery, Laundry pickup service, Laundry pickup, Dry cleaning, Dry cleaning delivery, Laundry delivery, Laundry delivery service, Delivery, Stockton, 95219, 95207, 95376, Tracy, Livermore, Modesto, Manteca, Lodi, Discovery bay"
        />
      </Helmet>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <HeroSection />
      <Services />
      <InfoSection {...homeObjOne} />
      <InfoSection {...homeObjTwo} />
      <InfoSection {...homeObjThree} />
      <Footer />
    </>
  );
}

export default Home;
