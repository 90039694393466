import React, { useState } from 'react';
import WashAndFold from '../components/ServicesPricing/WashAndFold';
import DryCleaning from '../components/ServicesPricing/DryCleaning';
import OtherServices from '../components/ServicesPricing/OtherServices';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet';

function ServicesPage() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <Helmet>
        <title>Tumble Services</title>
        <meta
          name="description"
          content="Tumble offers Wash and Fold, Dry Cleaning, as well as special requests like commercial laundering, hang dry, and wash and hang."
        />
        <meta
          name="keywords"
          content="commercial laundering, hang dry, Laundry near me, Laundry, Laundromat, Wash and fold, Wash and fold service, Pickup and delivery, Laundry pickup service, Laundry pickup, Dry cleaning, Dry cleaning delivery, Laundry delivery, Laundry delivery service, Delivery, Stockton, 95219, 95207, 95376, Tracy, Livermore, Modesto, Manteca, Lodi, Discovery bay"
        />
      </Helmet>
      <Switch>
        <Route path="/services/wash-and-fold" component={WashAndFold} />
        <Route path="/services/dry-cleaning" component={DryCleaning} />
        <Route path="/services/other-services" component={OtherServices} />
        <Redirect from="/services" to="/services/wash-and-fold" />
      </Switch>
    </>
  );
}

export default ServicesPage;
