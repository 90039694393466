import React, { useState } from 'react';
import Navbar from '../Navbar';
import Sidebar from '../Sidebar';
import Footer from '../Footer';
import {
  HeroContainer,
  HeroHeader,
  LeftDiv,
  RightDiv,
  NavBtn,
  NavBtnLink,
  Img,
  ImgWrap
} from '../ServicesPricing/ServicePricingElements';
import ScrollToTop from '../ScrollToTop';

function TestimonialsPage() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };
  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <div>
        <ScrollToTop />
        <HeroHeader></HeroHeader>
        <div style={{ paddingLeft: "100px", marginBottom: "50px" }}>
          <h1 style={{ paddingTop: "80px", fontSize: "55px" }}>
            Testimonials
          </h1>
          <h3 style={{ paddingTop: "60px", color: "#4E8AA2", fontSize: "27px", paddingBottom: "15px" }}>
            Shelia
          </h3>
          <p style={{ width: "70%", fontSize: "23px" }}>Tumble makes my life just a little bit easier.  We started using their service when I had a major surgery to help my husband out while he took care of everything in the house including me. It's truly been a life saver. They have great service and I recommend them to anyone who has a busy life.</p>
          <h3 style={{ paddingTop: "60px", color: "#4E8AA2", fontSize: "27px", paddingBottom: "15px" }}>
            Brian
          </h3>
          <p style={{ width: "70%", fontSize: "23px" }}>Tumble has changed my life and allowed me the freedom to do more living.  Tumble staff are flexible and good communicators allowing me to schedule at my convenience.  The value of this product cannot be measured in dollars and I encourage all of my friends and family to use their service.</p>
          <h3 style={{ paddingTop: "60px", color: "#4E8AA2", fontSize: "27px", paddingBottom: "15px" }}>
            Stephanie
          </h3>
          <p style={{ width: "70%", fontSize: "23px" }}>Tumble saved our sanity! As working mom & dad, work picked up and something had to give! We found Tumble at just the right time and it made sense to outsource. They pick up, fold, & deliver. We know when they were coming - always communicate and are on time. We have no issues whatsoever and highly recommend Tumble. Laundry is a no-brainer task to hand off and when you can trust your team - you can relieve some stress of everyday chores. We highly recommend Tumble!</p>
          <h3 style={{ paddingTop: "60px", color: "#4E8AA2", fontSize: "27px", paddingBottom: "15px" }}>
            Maria
          </h3>
          <p style={{ width: "70%", fontSize: "23px" }}>Tumble Delivery services will free up your time and help you cross things off from your weekend to-do list!!  I had bed sheets that needed to be washed but with my busy schedule I really was fighting time to get to it. I used Tumble to get my bed sheets washed and out of the way and I scheduled a time to pick up my laundry. You can also have it delivered.  Not only was I able to run my errands but by the end of my day I had clean sheets to put on my bed!  Would recommend this service to everyone!</p>
          <h3 style={{ paddingTop: "60px", color: "#4E8AA2", fontSize: "27px", paddingBottom: "15px" }}>
            Jasmine
          </h3>
          <p style={{ width: "70%", fontSize: "23px" }}>I use Tumble Delivery to help my elderly parents. This service is great for them and convenient with no contact pick up and delivery. They just put their laundry in the bag and put it at the door when they see the driver arriving. No need to worry about going to the laundry mat during this pandemic.</p>
          <h3 style={{ paddingTop: "60px", color: "#4E8AA2", fontSize: "27px", paddingBottom: "15px" }}>
            Adriana
          </h3>
          <p style={{ width: "70%", fontSize: "23px" }}>This business is a lifesaver! Our family is so busy & hardly ever home. When we are home we’re trying to balance family time, dinner & relaxation, but laundry is also a necessity so Tumble Delivery is just the perfect solution!! They’re so kind & professional & I can’t recommend them enough!</p>
          <h3 style={{ paddingTop: "60px", color: "#4E8AA2", fontSize: "27px", paddingBottom: "15px" }}>
            Corriana
          </h3>
          <p style={{ width: "70%", fontSize: "23px" }}>I have been using Tumble for a few months now, and I am very happy with it. As a working mom and student, I have found it so convenient to just have my clothes picked up and dropped off back to me. Everything is folded nicely. Their customer service is amazing. They usually try to accommodate special requests, which I appreciate. Overall, I will definitely continue to use Tumble, I even got my aunt to start using Tumble also 🙂🙂🙂🙂</p>
          <h3 style={{ paddingTop: "60px", color: "#4E8AA2", fontSize: "27px", paddingBottom: "15px" }}>
            Chelle
          </h3>
          <p style={{ width: "70%", fontSize: "23px" }}>Tumble makes my life so easy. My laundry is always picked up on time, and dropped off a few days later. The laundry always smells fresh and is nicely folded. Love this business!</p>
          <h3 style={{ paddingTop: "60px", color: "#4E8AA2", fontSize: "27px", paddingBottom: "15px" }}>
            Lesley
          </h3>
          <p style={{ width: "70%", fontSize: "23px" }}>Tumble really helped me out when I couldn't get to a laundromat.  I totally recommend people use this service. I appreciate that they was able to get my clothes back to me the next day. I will definitely use them again.</p>
          <h3 style={{ paddingTop: "60px", color: "#4E8AA2", fontSize: "27px", paddingBottom: "15px" }}>
            MAN B
          </h3>
          <p style={{ width: "70%", fontSize: "23px" }}>Outstanding work 👏 👌 👍 🙌! This is the best THE BEST drop-off  service ever!</p>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default TestimonialsPage;
