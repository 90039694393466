import React, { useState } from 'react';
import Navbar from '../Navbar';
import Sidebar from '../Sidebar';
import Footer from '../Footer';
import {
  HeroContainer,
  HeroHeader,
  LeftDiv,
  RightDiv,
  NavBtn,
  NavBtnLink,
  Img,
  ImgWrap
} from '../ServicesPricing/ServicePricingElements';
import ScrollToTop from '../ScrollToTop';

function FAQPage() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };
  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <div>
        <ScrollToTop />
        <HeroHeader></HeroHeader>
        <div style={{ paddingLeft: "100px", marginBottom: "50px" }}>
          <h1 style={{ paddingTop: "80px", fontSize: "55px" }}>
            FAQs
          </h1>
          <h3 style={{ paddingTop: "60px", color: "#4E8AA2", fontSize: "27px", paddingBottom: "15px" }}>
            When will I receive my Tumble Bag(s)?
          </h3>
          <p style={{ width: "70%", fontSize: "23px" }}>Your Tumble bag(s) will be given to you on the first scheduled pickup of your plan.  Tumble bags are not given for one time purchases.</p>
          <h3 style={{ paddingTop: "60px", color: "#4E8AA2", fontSize: "27px", paddingBottom: "15px" }}>
            When will I receive my laundry back?
          </h3>
          <p style={{ width: "70%", fontSize: "23px" }}>Standard delivery is 2-4 business days after pickup.</p>
          <h3 style={{ paddingTop: "60px", color: "#4E8AA2", fontSize: "27px", paddingBottom: "15px" }}>
            Does Tumble deliver to my area?
          </h3>
          <p style={{ width: "70%", fontSize: "23px" }}>We are a California-based business which serves the following cities: Stockton, Tracy, Manteca, Modesto, and Livermore. (Please contact us for more information)</p>
          <h3 style={{ paddingTop: "60px", color: "#4E8AA2", fontSize: "27px", paddingBottom: "15px" }}>
            How do I schedule my first Tumble delivery?
          </h3>
          <p style={{ width: "70%", fontSize: "23px" }}>To schedule your first delivery, you will need to create a free Tumble account. Once you have completed your Tumble account, select the one time pickup button on the home page. Follow the prompts to schedule the date and time of your first pickup. Once you confirm your payment method, there will be a notification shown on your homescreen. If you have any questions or concerns please call or email us.</p>
          <h3 style={{ paddingTop: "60px", color: "#4E8AA2", fontSize: "27px", paddingBottom: "15px" }}>
            Are there items that Tumble does not clean?
          </h3>
          <p style={{ width: "70%", fontSize: "23px" }}>There are only a few items that Tumble cannot accept due to the risk they may pose to our team.</p>
          <p style={{ width: "70%", fontSize: "23px", paddingTop: "10px" }}>We cannot accept the following items for cleaning: Pillows, Curtains, Commercial linens, Sofa covers, Area rugs, Sleeping bags, Wedding dresses, Vintage clothes, Costumes, Hats/Headwear, Shoes, Fur & Animal Products (feathers, fur coats), Purses/handbags, Contaminated items.</p>
          <p style={{ width: "70%", fontSize: "23px", paddingTop: "10px" }}>We cannot accept items that have been contaminated with: Bodily fluids from a human or pet, Excessive pet hair, Moisture if the item is still wet or damp, Mildew, Bedbugs, Poison ivy or poison oak oils, Hazardous chemical residue.</p>
          <p style={{ width: "70%", fontSize: "23px", paddingTop: "10px" }}>We cannot accept these items because they may pose a risk to the safety of our employees and/or they may pose a risk to the items of other customers cleaned in the same facility.</p>
          <h3 style={{ paddingTop: "60px", color: "#4E8AA2", fontSize: "27px", paddingBottom: "15px" }}>
            Can I choose my detergent?
          </h3>
          <p style={{ width: "70%", fontSize: "23px" }}>You may choose between the following scent options: Tide, Kirkland Ocean Scent, Gain, or Scent free</p>
          <h3 style={{ paddingTop: "60px", color: "#4E8AA2", fontSize: "27px", paddingBottom: "15px" }}>
            Can I receive a pickup or delivery if I’m not home?
          </h3>
          <p style={{ width: "70%", fontSize: "23px" }}>Yes! We have an ‘empty drop off’ option available free of charge. Since every situation is different, please contact us to identify what the best plan of action would be.</p>
          <h3 style={{ paddingTop: "60px", color: "#4E8AA2", fontSize: "27px", paddingBottom: "15px" }}>
            Can I tip my Tumble Associate?
          </h3>
          <p style={{ width: "70%", fontSize: "23px" }}>We work very hard to make sure each experience goes above and beyond expectations. If you feel like this is the case, please feel free to tip our associates.</p>
          <h3 style={{ paddingTop: "60px", color: "#4E8AA2", fontSize: "27px", paddingBottom: "15px" }}>
            Do you accept clothing donations?
          </h3>
          <p style={{ width: "70%", fontSize: "23px" }}>Currently, we do not accept any clothing donations. </p>
          <h3 style={{ paddingTop: "60px", color: "#4E8AA2", fontSize: "27px", paddingBottom: "15px" }}>
            Do you clean leather or offer any repair services?
          </h3>
          <p style={{ width: "70%", fontSize: "23px" }}>Currently, we do not offer any leather cleaning or repair services. But we can provide you with a couple of suggestions depending on your area.</p>
          <h3 style={{ paddingTop: "60px", color: "#4E8AA2", fontSize: "27px", paddingBottom: "15px" }}>
            Do you have a hand wash option?
          </h3>
          <p style={{ width: "70%", fontSize: "23px" }}>Yes, we offer a hand wash service. Please contact your Tumble associate to request a hand wash bag.</p>
          <h3 style={{ paddingTop: "60px", color: "#4E8AA2", fontSize: "27px", paddingBottom: "15px" }}>
            How much laundry does a Tumble bag hold?
          </h3>
          <p style={{ width: "70%", fontSize: "23px" }}>A Tumble bag holds 20 lbs or about 2 - 3 medium sized loads of laundry.</p>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default FAQPage;
