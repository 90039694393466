import React, { useState } from 'react';
import { Button } from '../ButtonElements';
import Navbar from '../Navbar';
import Sidebar from '../Sidebar';
import Footer from '../Footer';
import {
  HeroContainer,
  HeroHeader,
  LeftDiv,
  RightDiv,
  NavBtn,
  NavBtnLink,
  Img,
  ImgWrap,
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesCardNoClick,
  ServicesIcon,
  ServicesH2,
  ServicesP
} from './ServicePricingElements';
import {
  
} from '../Services/ServicesElements';
import WasherIcon from '../../images/washer.png'
import DryCleaningIcon from '../../images/dry-clean.png'
import ScrollToTop from '../ScrollToTop';
import { Helmet } from 'react-helmet';

function OtherServices() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };
  return (
    <>
      <Helmet>
        <title>Other Services</title>
        <meta
          name="description"
          content="Tumble also offers other services on special request, including commercial laundering, hang dry, and wash and hang.  Contact us for more details."
        />
        <meta
          name="keywords"
          content="commercial laundering, hang dry, Laundry near me, Laundry, Laundromat, Wash and fold, Wash and fold service, Pickup and delivery, Laundry pickup service, Laundry pickup, Dry cleaning, Dry cleaning delivery, Laundry delivery, Laundry delivery service, Delivery, Stockton, 95219, 95207, 95376, Tracy, Livermore, Modesto, Manteca, Lodi, Discovery bay"
        />
      </Helmet>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <div>
        <ScrollToTop />
        <HeroHeader></HeroHeader>
        {/* <div style={{ width: "100%", textAlign: "center" }}>
        <h1 style={{ paddingTop: "20px", paddingBottom: "20px", color: "#4E8AA2", margin: "auto" }}>Services and Pricing</h1>
      </div> */}
        <div style={{ width: "100%", textAlign: "center", backgroundColor: "#92c1d4" }}>
          <table style={{ width: "100%", textAlign: "center", paddingTop: "20px", paddingBottom: "20px" }}>
            <tr>
              <th><a href="/services/wash-and-fold" style={{ color: "black", textDecoration: "none" }}>Wash & Fold</a></th>
              <th><a href="/services/dry-cleaning" style={{ color: "black", textDecoration: "none" }}>Dry Cleaning</a></th>
              <th><a href="/services/other-services" style={{ color: "black" }}>Other Services</a></th>
            </tr>
          </table>
        </div>
        <h1 style={{ paddingTop: "80px", fontSize: "55px", textAlign: 'center' }}>
          Other Services
        </h1>
        <ServicesContainer id='services'>
          <ServicesWrapper>
            <ServicesCardNoClick>
              <ServicesIcon src={WasherIcon} />
              <ServicesH2>Commercial Laundering</ServicesH2>
              <ServicesP>
              We provide laundry services to commercial properties as well. If you would like more information, please contact our team.
              </ServicesP>
            </ServicesCardNoClick>
            <ServicesCardNoClick>
              <ServicesIcon src={DryCleaningIcon} />
              <ServicesH2>Hang Dry</ServicesH2>
              <ServicesP>
              Use either our hang dry or wash and hang for delicates, activewear, and other clothing that should avoid heat from the dryer. Machine-washed and hung to dry.
              </ServicesP>
            </ServicesCardNoClick>
            <ServicesCardNoClick>
              <ServicesIcon src={DryCleaningIcon} />
              <ServicesH2>Wash and Hang</ServicesH2>
              <ServicesP>
              This option is perfect for you if you’d like to have your items returned to you on hangers instead of folded. You would just need to provide us with enough hangers to hang your items and specify which items you would like hung on hangers.
              </ServicesP>
            </ServicesCardNoClick>
          </ServicesWrapper>
        </ServicesContainer>
      </div>
      <Footer />
    </>
  );
}

export default OtherServices;
