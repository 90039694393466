import React, { useState } from 'react';
import Contact from '../components/Contact';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet';

function ContactPage() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <Helmet>
        <title>Contact Us</title>
        <meta
          name="description"
          content="Contact us if you'd like to learn more, if you have any questions, or if you'd like to request special services."
        />
        <meta
          name="keywords"
          content="commercial laundering, hang dry, Laundry near me, Laundry, Laundromat, Wash and fold, Wash and fold service, Pickup and delivery, Laundry pickup service, Laundry pickup, Dry cleaning, Dry cleaning delivery, Laundry delivery, Laundry delivery service, Delivery, Stockton, 95219, 95207, 95376, Tracy, Livermore, Modesto, Manteca, Lodi, Discovery bay"
        />
      </Helmet>
      <Switch>
        <Route path="/contact" component={Contact} />
      </Switch>
    </>
  );
}

export default ContactPage;
